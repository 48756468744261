<template>
  <van-action-bar style="background: rgba(0, 0, 0, 0)">
    <van-button type="primary" @click="this.$emit('update:show', false)">关闭</van-button>
  </van-action-bar>

  <van-overlay :show="show" style="background: #FFFFFF">
    <div fixed
         class="scan-index-bar">
      <span>
      扫描二维码/条形码 未成功扫描8s自动关闭

      </span>
    </div>
    <div class="page-scan">
      <!-- 扫码区域 -->
      <video  ref="video" id="video" class="scan-video" muted autoplay></video>
      <!-- 提示语 -->
      <div v-show="tipShow" class="scan-tip"> {{ tipMsg }} <van-action-bar style="background: rgba(0, 0, 0, 0)">
        <van-action-bar-button type="primary" @click="this.$emit('update:show', false)">关闭</van-action-bar-button>
      </van-action-bar></div>
      <van-action-bar style="background: rgba(0, 0, 0, 0)">
        <van-action-bar-button type="primary" @click="this.$emit('update:show', false)">关闭</van-action-bar-button>
      </van-action-bar>
    </div>

  </van-overlay>
</template>

<script>
import {BrowserMultiFormatReader} from '@zxing/library';
import {Dialog, Notify, Toast} from 'vant';

export default {
  name: 'QRReader',

  props: {
    show: {
      default: false,
      required: true,
      type: Boolean
    }
  },

  emits: ['onRead', 'update:show'],

  setup() {
  },

  data() {
    return {
      showPopup: false,
      loadingShow: false,
      codeReader: null,
      scanText: '',
      vin: null,
      tipMsg: '正在识别中....',
      tipShow: false,
      hadShut:false
    }
  },
  created() {

  },
  unmounted() {
  },
  watch: {
    show: function (val) {
      this.hadShut=!val
      if (this.showPopup !== val) {
        this.showPopup = val
      }
      if (val) {
        this.codeReader = new BrowserMultiFormatReader();
        this.openScan();

      } else {
        this.hadShut=true
        this.codeReader.reset();
        this.codeReader = null;
      }
      setTimeout(() => {
        if(!this.hadShut){
          this.hadShut=true
          this.$emit('update:show', false)
        }
      }, 8000)

    },
  },
  methods: {

    async openScan() {
      this.codeReader.listVideoInputDevices().then((MediaDeviceInfos) => {
        this.tipShow = true;
        this.tipMsg = '正在调用摄像头...';
        if (MediaDeviceInfos.length === 0) {
          this.tipMsg = '调用摄像头失败';
          return;
        }
        // 默认获取第一个摄像头设备id
        let firstDeviceId = MediaDeviceInfos[0].deviceId;
        // 获取第一个摄像头设备的名称
        const videoInputDeviceslablestr = JSON.stringify(MediaDeviceInfos[0].label);
        if (MediaDeviceInfos.length > 1) {
          // 判断是否后置摄像头
          if (videoInputDeviceslablestr.indexOf('back') > -1) {
            firstDeviceId = MediaDeviceInfos[0].deviceId;
          } else {
            firstDeviceId = MediaDeviceInfos[1].deviceId;
          }
        }
        this.decodeFromInputVideoFunc(firstDeviceId);
      }).catch(err => {
        this.tipShow = false;
        console.error(err);
      });
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      this.codeReader.reset(); // 重置
      this.scanText = '';
      this.codeReader.decodeFromVideoDevice(firstDeviceId, 'video', (result, err) => {
        this.tipMsg = '正在识别中...';
        this.scanText = '';
        if (result) {
          console.log('扫描结果', result);
          this.scanText = result.text;
          if (this.scanText) {
            this.tipShow = false;
            if (!_.isEmpty(this.scanText)) {
              Toast('识别成功！');
              this.$emit('update:show', false)
              this.$emit('onRead', this.scanText)
            }
          }
        }
        if (err && !(err)) {
          this.tipMsg = '识别失败';
          setTimeout(() => {
            this.tipShow = false;
          }, 2000)
          console.error(err);
        }
      });
    },
  }
}
</script>

<style scoped>
.scan-index-bar {
  background-color: white;
  height: 25px;
  text-align: center;
  font-size: 18px;
}

.scan-video {
  width: 100%;
}

.scan-tip {
  width: 100vw;
  text-align: center;
  margin-bottom: 10vh;
  font-size: 5vw;
}

.page-scan {
  overflow: hidden;
}
</style>